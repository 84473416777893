<template>
  <div class="payment">
    <checkout-navigation
      ref="checkoutNavigation"
      v-model="componentName"
    ></checkout-navigation>
    <div
      v-if="isShowPcForm"
      class="payment__body"
    >
      <div class="payment__body__order-info">
        <contact-information
          v-show="componentName === 'contact-information'"
          :payment-source="paymentSource"
          :payment-info="paymentInfo"
          @updateCurrentStep="updateCurrentStep"
          @sendBuryPointer="sendBuryPointer"
          @hiddenNavigation="hiddenNavigation"
        ></contact-information>
        <shipping-method
          v-show="componentName === 'shipping-method'"
          :component-name="componentName"
          :payment-info="paymentInfo"
          @updateCurrentStep="updateCurrentStep"
          @sendBuryPointer="sendBuryPointer"
        ></shipping-method>
        <checkout-method
          v-show="componentName === 'checkout-method'"
          :payment-source="paymentSource"
          :payment-info="paymentInfo"
          @updateCurrentStep="updateCurrentStep"
          @sendBuryPointer="sendBuryPointer"
        ></checkout-method>
      </div>
      <div class="payment__body__order-summary">
        <order-summary
          :payment-source="paymentSource"
          :payment-info="paymentInfo"
        ></order-summary>
      </div>
    </div>
    <div
      v-if="!isShowPcForm"
      class="payment__body payment__body--vertical"
    >
      <div class="payment__body__order-summary">
        <order-summary
          :payment-source="paymentSource"
          :payment-info="paymentInfo"
        ></order-summary>
      </div>
      <div class="payment__body__order-info">
        <contact-information
          v-show="componentName === 'contact-information'"
          :payment-source="paymentSource"
          :payment-info="paymentInfo"
          @updateCurrentStep="updateCurrentStep"
          @sendBuryPointer="sendBuryPointer"
          @hiddenNavigation="hiddenNavigation"
        ></contact-information>
        <shipping-method
          v-show="componentName === 'shipping-method'"
          :component-name="componentName"
          :payment-info="paymentInfo"
          @updateCurrentStep="updateCurrentStep"
          @sendBuryPointer="sendBuryPointer"
        ></shipping-method>
        <checkout-method
          v-show="componentName === 'checkout-method'"
          :payment-source="paymentSource"
          :payment-info="paymentInfo"
          @updateCurrentStep="updateCurrentStep"
          @sendBuryPointer="sendBuryPointer"
        ></checkout-method>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutNavigation from './components/checkout-navigation.vue'
import ContactInformation from './components/contact-information.vue'
import ShippingMethod from './components/shipping-method.vue'
import OrderSummary from './components/order-summary.vue'
import CheckoutMethod from './components/checkout-method.vue'
import { localStorage, sessionStorage, MallAnalytics } from '@/utils/index'
import { getPaymentDetail } from '@/api'
import { isEmptyValue } from '@/utils/validator'
import { PAY_STATUS, SHIPPING_TYPE } from '@/constants'
const codCode = 7
export default {
  name: 'Checkout',
  components: {
    CheckoutNavigation,
    ContactInformation,
    ShippingMethod,
    OrderSummary,
    CheckoutMethod,
  },
  props: {},
  data() {
    return {
      componentName: 'contact-information',
      contactInformation: {},
      shippingInformation: {},
      isShowPcForm: true,
      paymentInfo: {
        contactInformation: {},
        shippingInformation: {},
        taxRateList: [],
        costInfo: {},
        productList: [],
        discountCode: '',
        shippingType: SHIPPING_TYPE.LOGISTiCS,
        shopTakeAddressDTO: {},
      },
    }
  },
  computed: {
    paymentSource() {
      return this.$route.query.orderNo && this.$route.query.orderToken ? 'payByLink' : 'payByCart'
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.orderNo && to.query.orderToken) {
      next()
      return
    }
    const productList = localStorage.get('accountProductList', [])
    productList.length === 0 ? next({ path: '/' }) : next()
  },

  created() {
    this.paymentSource === 'payByLink' && this.getPaymentDetail()
  },

  mounted() {
    const width = document.documentElement.clientWidth
    if (width > 1024) {
      this.isShowPcForm = true
    } else {
      this.isShowPcForm = false
    }
    window.addEventListener('resize', this.resizeScreen)
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeScreen)
  },

  methods: {
    hiddenNavigation() {
      this.$refs.checkoutNavigation.hiddenShippingMethod()
    },
    resizeScreen(e) {
      if (e.target.innerWidth > 1024) {
        this.isShowPcForm = true
      } else {
        this.isShowPcForm = false
      }
    },

    checkContactInfoIsValid(contactInfo) {
      const keyList = [
        'email',
        'address',
        'city',
        'cityCode',
        'countryCode',
        'firstName',
        'internationalCode',
        'lastName',
        'phone',
        'stateCode',
        'stateValue',
        'zipCode',
      ]
      const index = keyList.findIndex(item => isEmptyValue(contactInfo[item]))
      return index === -1
    },

    checkShippingInfoIsValid(shippingInfo) {
      const keyList = ['id', 'name', 'amount']
      const index = keyList.findIndex(item => isEmptyValue(shippingInfo[item]))
      return index === -1
    },

    async getPaymentDetail() {
      const { data, code, message } = await getPaymentDetail({ ...this.$route.query })
      if (code === 9002) {
        this.$router.replace({ path: '/checkout/disabled' })
        return
      }
      if (code !== 200) {
        this.$message.error(message)
        return
      }
      const {
        orderItemDTOList,
        orderDeliveryAddressDTO,
        customerOrderBaseResultDTO,
        orderTaxResultDTOList,
        shopTakeAddressDTO,
      } = data || {}
      if (
        customerOrderBaseResultDTO.payStatus === PAY_STATUS.PAY_SUCCESS ||
        customerOrderBaseResultDTO.payMethodType === codCode
      ) {
        const query = this.$route.query
        this.$router.replace({
          path: `/order/${query.orderNo}`,
          query: { orderToken: query.orderToken },
        })
        return
      }
      this.paymentInfo = {
        ...this.paymentInfo,
        contactInformation: {
          ...orderDeliveryAddressDTO,
          email: customerOrderBaseResultDTO?.customerEmail,
        },
        shippingInformation: {
          id: orderDeliveryAddressDTO?.shippingTypeId,
          name: orderDeliveryAddressDTO?.shippingTypeName,
          amount: customerOrderBaseResultDTO?.deliveryAmount,
        },
        costInfo: {
          subTotalAmount: customerOrderBaseResultDTO?.originalAmount,
          discountAmount: customerOrderBaseResultDTO?.orderDiscountAmount,
          taxAmount: customerOrderBaseResultDTO?.taxAmount,
          totalAmount: customerOrderBaseResultDTO?.afterTaxAmount,
        },
        productList: orderItemDTOList ?? [],
        taxRateList: orderTaxResultDTOList,
        shopTakeAddressDTO: {
          email: customerOrderBaseResultDTO?.customerEmail,
          firstName: shopTakeAddressDTO?.firstName,
          lastName: shopTakeAddressDTO?.lastName,
        },
      }
      localStorage.set('accountProductList', orderItemDTOList)
      if (!this.checkContactInfoIsValid(this.paymentInfo.contactInformation)) {
        this.updateCurrentStep('contact-information')
        return
      }
      if (!this.checkShippingInfoIsValid(this.paymentInfo.shippingInformation)) {
        this.updateCurrentStep('shipping-method')
        return
      }
      this.updateCurrentStep('checkout-method')
    },

    // 埋点
    sendBuryPointer(eventName) {
      const productList = (this.paymentInfo.productList || []).map(item => {
        return {
          spuId: item.goodsId,
          spuName: item.goodsName,
          skuId: item.skuId,
          salePrice: item.salePrice,
          skuNum: item.skuNum,
        }
      })
      const params = {
        subTotal: this.paymentInfo?.costInfo?.subTotalAmount,
        productList,
        ...sessionStorage.get('channelCode', {}),
      }
      if (eventName === 'spmall_payment_makepayment_click') {
        params.orderTotal = this.paymentInfo?.costInfo?.totalAmount
      }
      MallAnalytics.mc(eventName, params)
    },

    updateCurrentStep(componentName) {
      this.componentName = componentName
    },
  },
}
</script>
<style lang="less" scoped>
.payment {
  width: 1440px;
  max-width: calc(100% - 80px);
  padding-top: 16px;
  margin: 0 auto;
  &__body {
    display: flex;
    margin: 16px 0;
    &__order-info {
      flex: 1;
      width: 0;
      padding: 16px;
      margin-right: 24px;
      background: #fff;
      border-radius: 4px;
    }
    &__order-summary {
      width: 300px;
      background: #fff;
      border-radius: 4px;
    }
  }

  /deep/ .payment-step__operation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
    &__return {
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }
}
@media (max-width: 1024px) {
  .payment {
    max-width: calc(100% - 80px);
    &__body {
      display: block;
      width: 100%;
      &__order-info {
        width: 100%;
      }
      &__order-summary {
        width: 100%;
      }
    }
  }
}
@media (max-width: 768px) {
  .payment {
    max-width: calc(100% - 32px);
    /deep/ .payment-step__operation {
      flex-wrap: wrap;
      width: 100%;
      &__return {
        display: block;
        order: 1;
        width: 100%;
        margin-bottom: 16px;
        text-align: center;
      }
      .op-button {
        order: 0;
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}
@media (max-width: 576px) {
  .payment {
    max-width: calc(100% - 32px);
  }
}
</style>
