<template>
  <div class="payment-navigation">
    <div
      class="payment-navigation__item"
      @click="goCart"
    >
      <span
        class="payment-navigation__item__cart-navigation"
      >{{ $t('payment.navigation.shoppingCart') }}</span>
      <a-icon
        class="payment-navigation__item__navigation-arrows"
        :style="{ color: '#8991A4', fontSize: '14px' }"
        type="right"
      />
    </div>
    <div
      v-for="item in navigationConfig"
      :key="item.navigationName"
      class="payment-navigation__item"
      @click="changeNavigation(item)"
    >
      <div v-if="item.showItem">
        <span
          :class="
            value === item.componentName && 'payment-navigation__item__navigation-name--active'
          "
          class="payment-navigation__item__navigation-name"
        >{{ $t(item.navigationName) }}</span>
        <a-icon
          v-if="item.showArrows"
          class="payment-navigation__item__navigation-arrows"
          :style="{ color: '#8991A4', fontSize: '14px' }"
          type="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentNavigation',
  components: {},
  props: {
    value: {
      type: String,
      default: 'contact-information',
    },
  },
  data() {
    return {
      navigationConfig: [
        {
          navigationName: 'payment.navigation.information',
          componentName: 'contact-information',
          showArrows: true,
          showItem: true,
          step: 1,
        },
        {
          navigationName: 'payment.navigation.shipping',
          componentName: 'shipping-method',
          showArrows: true,
          showItem: true,
          step: 2,
        },
        {
          navigationName: 'payment.navigation.payMethod',
          componentName: 'checkout-method',
          showArrows: false,
          showItem: true,
          step: 3,
        },
      ],
    }
  },

  computed: {
    currentStep() {
      return (
        this.navigationConfig.find(item => {
          return item.componentName === this.value
        }).step || 1
      )
    },
  },

  methods: {
    goCart() {
      this.$router.push({
        path: '/cart',
      })
    },
    hiddenShippingMethod() {
      this.navigationConfig[1].showItem = !this.navigationConfig[1].showItem
    },
    changeNavigation(item) {
      if (this.currentStep <= item.step) {
        return
      }
      this.$emit('input', item.componentName)
    },
  },
}
</script>
<style lang="less" scoped>
.payment-navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  line-height: 30px;
  &__item {
    display: flex;
    align-items: center;
    color: #8991a4;
    cursor: pointer;
    &__cart-navigation {
      color: var(--color-text);
    }
    &__navigation-arrows {
      margin: 0 8px;
    }
    &__navigation-name--active {
      color: var(--color-text);
    }
  }
}
</style>
