<template>
  <div class="shipping-type-check">
    <div class="shipping-type-check__title">{{ $t('payment.shipping.method') }}</div>
    <a-radio-group
      v-model="shippingType"
      @change="onChange"
    >
      <div
        v-for="item in SHIPPING_TYPE_TEXT_MAP_LIST"
        :key="item.value"
        class="shipping-type-check__item"
      >
        <a-radio :value="item.value">{{ item.label }}</a-radio>
      </div>
    </a-radio-group>
  </div>
</template>

<script>
import { SHIPPING_TYPE } from '@/constants/index'
export default {
  name: 'ShippingTypeCheck',
  components: {},
  props: {
    paymentInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      SHIPPING_TYPE_TEXT_MAP_LIST: [
        {
          label: $t('payment.shipping.logistics'),
          value: 1,
        },
        {
          label: $t('payment.shipping.pickUPInStore'),
          value: 2,
        },
      ],
      shippingType: SHIPPING_TYPE.LOGISTiCS,
    }
  },
  computed: {},
  created() {},
  methods: {
    onChange() {
      this.$emit('change', this.shippingType)
    },
  },
}
</script>
<style lang="less" scoped>
.shipping-type-check {
  &__title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: var(--color-title);
  }
  &__item {
    padding: 0 0 18px 24px;
  }
}
</style>
