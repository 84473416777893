<template>
  <div class="payment-method">
    <div class="payment-method__row">
      <div class="payment-method__row__title">{{ $t('payment.contactInformation') }}</div>
      <div class="payment-method__row__body">
        <div class="payment-method__row__body__text">{{ email }}</div>
        <op-link
          v-if="!isPickUpInStore"
          @click="changeAddress('contact-information')"
        >{{ $t('payment.update') }}</op-link>
      </div>
    </div>
    <div class="payment-method__row">
      <div class="payment-method__row__title">{{ $t('payment.shipping.deliverTo') }}</div>
      <div class="payment-method__row__body">
        <div class="payment-method__row__body__text text-overflow-ellipsis">{{ addressInfo }}</div>
        <op-link
          v-if="!isPickUpInStore"
          @click="changeAddress('contact-information')"
        >{{ $t('payment.update') }}</op-link>
      </div>
    </div>
    <div class="payment-method__row">
      <div class="payment-method__row__title">{{ $t('payment.shipping.method') }}</div>
      <div class="payment-method__row__body">
        <div class="payment-method__row__body__text text-overflow-ellipsis">
          <span>{{ name }}</span>
          <span
            v-if="paymentInfo.shippingInformation.amount && !isPickUpInStore"
            class="payment-method__row__body__text--margin"
          >{{ paymentInfo.shippingInformation.amount | formatMoney }}</span>
          <span
            v-else
            class="payment-method__row__body__text--margin"
          >{{ $t('payment.shipping.free') }}</span>
        </div>
        <op-link
          v-if="!isPickUpInStore"
          @click="changeShipping('shipping-method')"
        >{{ $t('payment.update') }}</op-link>
      </div>
    </div>

    <a-row
      type="flex"
      justify="space-between"
      align="middle"
      class="payment-method__title-area"
    >
      <div class="payment-method__title">{{ $t('payment.checkout.method') }}</div>
      <div class="payment-method__title__logo">
        <img src="https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/payment/opay.png" />
        {{ $t('payment.checkout.providedByOpay') }}
      </div>
    </a-row>
    <div
      v-if="shopInfo.paymentOpened"
      class="payment-method__list"
    >
      <a-radio-group
        v-model="payMethod"
        style="width: 100%"
      >
        <div
          v-for="item in payMethodList"
          :key="item.code"
          class="payment-method__list__item"
        >
          <a-row
            type="flex"
            justify="space-between"
            align="middle"
            :gutter="[8, 8]"
          >
            <a-col>
              <a-radio
                :value="item.code"
                @click="getChannelCode(item)"
              >
                <img
                  :src="item.payMethodIcon"
                  class="payment-method__list__item__payMethodIcon"
                />
                {{ item.name }}
              </a-radio>
            </a-col>
            <a-col>
              <span v-if="item.icons">
                <img
                  v-for="(imgItem, index) in item.icons"
                  :key="index"
                  :src="imgItem"
                  class="payment-method__list__item__logo"
                />
              </span>
            </a-col>
          </a-row>
        </div>
      </a-radio-group>
    </div>
    <div v-else>{{ $t('payment.checkout.noCheckout') }}</div>
    <div class="payment-step__operation">
      <op-button
        :loading="loading"
        :disabled="!shopInfo.paymentOpened"
        @click="checkoutOrder"
      >{{ $t('payment.checkout.payNow') }}</op-button>
    </div>
    <a-modal
      :closable="false"
      :mask-closable="false"
      :visible="visible"
    >
      <div>{{ $t('payment.changedLink') }}</div>
      <template slot="footer">
        <op-button @click="closLinkChangedTip">{{ $t('common.okay') }}</op-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { checkoutOrder, deleteShoppingCartProduct, getPayMethod } from '@/api'
import { ORDER_SOURCE, SHIPPING_TYPE } from '@/constants'
import { localStorage, sessionStorage, jumpLink } from '@/utils/index'
import { mapState } from 'vuex'
export default {
  name: 'CheckoutMethod',
  components: {},
  props: {
    paymentInfo: {
      type: Object,
      default: () => ({ contactInformation: {}, shippingInformation: {}, productList: [] }),
    },
    paymentSource: {
      type: String,
      default: 'payByCart',
    },
  },
  data() {
    return {
      SHIPPING_TYPE,
      payMethod: 6,
      loading: false,
      visible: false,
      payMethodList: [],
      channelCode: '',
    }
  },

  computed: {
    isPickUpInStore() {
      return this.paymentInfo.shippingType === SHIPPING_TYPE.PICK_UP
    },
    name() {
      const { shopTakeAddressDTO, shippingInformation } = this.paymentInfo
      return this.isPickUpInStore
        ? shopTakeAddressDTO.shippingRecordDTO?.name
        : shippingInformation?.name
    },
    email() {
      const { shopTakeAddressDTO, contactInformation } = this.paymentInfo
      return this.isPickUpInStore ? shopTakeAddressDTO?.email : contactInformation?.email
    },
    addressInfo() {
      if (this.isPickUpInStore) {
        const { stateValue, city, address } = this.paymentInfo.shopTakeAddressDTO
        return `${address}, ${city}, ${stateValue}`
      }
      const keyList = [
        'firstName',
        'lastName',
        'address',
        'city',
        'countryValue',
        'stateValue',
        'zipCode',
      ]
      return keyList
        .map(key => (this.paymentInfo.contactInformation || {})[key] || '')
        .filter(item => !!item)
        .join(',')
    },
    total() {
      if (this.isPickUpInStore) {
        return this.paymentInfo.costInfo.totalAmount
      }
      return (
        this.paymentInfo.costInfo.totalAmount + (this.paymentInfo.shippingInformation.amount || 0)
      )
    },
    ...mapState(['shopInfo', 'isAlreadyLogin']),
  },
  created() {
    this.getPayMethod()
  },
  methods: {
    getChannelCode(val) {
      this.channelCode = val.channelCode
    },
    async getPayMethod() {
      const { data } = await getPayMethod()
      this.payMethodList = data || []
      this.payMethodList.forEach((item, index) => {
        // 如果开通了opay支付，则默认选择bank card
        if (item.channelCode === 1 && item.code === 12) {
          this.payMethod = 12
          this.channelCode = item.channelCode
        }
        // 如果只开通了cod，则默认选中cod
        if (index === 0 && item.channelCode === 2) {
          this.payMethod = 7
          this.channelCode = item.channelCode
        }
      })
    },
    changeAddress(componentName) {
      this.$emit('updateCurrentStep', componentName)
    },
    changeShipping(componentName) {
      this.$emit('updateCurrentStep', componentName)
    },
    deleteShoppingCart() {
      this.isAlreadyLogin ? this.deleteShoppingCartOnline() : this.deleteShoppingCartLocal()
    },
    async deleteShoppingCartOnline() {
      const accountProductIds = (this.paymentInfo.productList || []).map(item => {
        return item.id
      })
      await deleteShoppingCartProduct({ idList: accountProductIds })
      this.$store.dispatch('updateCartNumber')
    },
    deleteShoppingCartLocal() {
      const allShoppingCart = localStorage.get('shoppingCart', [])
      const goodsIdList = (this.paymentInfo.productList || []).map(item => {
        return item.skuId + item.goodsId
      })
      const currentShoppingCart = allShoppingCart.filter(item => {
        return !goodsIdList.includes(item.skuId + item.goodsId)
      })
      localStorage.set('shoppingCart', currentShoppingCart || [])
      this.$store.dispatch('updateCartNumber')
    },

    closLinkChangedTip() {
      this.visible = false
      window.location.reload()
    },

    async checkoutOrder() {
      this.loading = true
      this.$emit('sendBuryPointer', 'spmall_payment_makepayment_click')
      const {
        productList,
        shippingInformation,
        contactInformation,
        costInfo,
        taxRateList,
        discountCode,
        shippingType,
        shopTakeAddressDTO,
      } = this.paymentInfo || {}
      try {
        const orderItemParamDTOList = (productList || []).map(item => {
          return {
            goodsGroupId: item.categoryId,
            goodsName: item.goodsName,
            goodsId: item.goodsId,
            skuId: item.skuId,
            skuNum: item.skuNum,
            costPrice: item.costPrice ?? 0,
            originalPrice: item.originalPrice ?? 0,
            salePrice: item.salePrice,
            imgUrl: item.imgUrl,
            orderItemSpecsParamDTOList:
              item.orderItemSpecsParamDTOList ?? item.orderItemSpecsDTOList,
            memo: '',
          }
        })
        const { email, ...addressInfo } = contactInformation
        const { shippingRecordDTO } = shopTakeAddressDTO
        const newOrderDeliveryAddressParamDTO = {
          ...addressInfo,
          shippingTypeId: shippingInformation?.id,
          shippingTypeName: shippingInformation?.name,
        }

        const amountInfo = {
          subTotal: costInfo.subTotalAmount,
          discountCode: discountCode,
          discountAmount: costInfo.discountAmount,
          deliveryAmount: this.isPickUpInStore
            ? shippingRecordDTO?.amount
            : shippingInformation?.amount,
          total: this.total,
        }
        const shopTakeAddressInfo = {
          ...shopTakeAddressDTO,
          shippingTypeId: shippingRecordDTO?.id,
          shippingTypeName: shippingRecordDTO?.name,
        }
        const host = window.location.protocol + '//' + window.location.host
        const callbackUrl = host + '/order/%s?orderToken=%s'

        const orderInfo = {
          ...amountInfo,
          email: shippingType === SHIPPING_TYPE.LOGISTiCS ? email : shopTakeAddressDTO?.email,
          orderSource: ORDER_SOURCE.WEB,
          orderItemParamDTOList,
          newOrderDeliveryAddressParamDTO:
            shippingType === SHIPPING_TYPE.LOGISTiCS ? newOrderDeliveryAddressParamDTO : null,
          orderTaxParamDTOList: taxRateList,
          returnUrl: callbackUrl,
          cancelPayUrl: callbackUrl,
          memo: '',
          payMethod: this.payMethod,
          shippingType,
          payChannelType: this.channelCode,
          shopTakeAddressDTO: shippingType === SHIPPING_TYPE.PICK_UP ? shopTakeAddressInfo : null,
        }
        const channelCode = sessionStorage.get('channelCode')
        if (channelCode) {
          orderInfo.orderSourceCodeParamDTO = channelCode
        }

        if (this.paymentSource === 'payByLink') {
          orderInfo.orderNo = this.$route.query.orderNo
        }
        const { data, code, message } = await checkoutOrder(orderInfo)
        if (code === 9012) {
          this.visible = true
          return
        }
        if (code !== 200) {
          this.$message.error(message)
          return
        }
        jumpLink(data && data.cashierUrl + '&from=paySuccess')
        this.paymentSource === 'payByCart' && this.deleteShoppingCart()
      } finally {
        this.loading = false
      }
    },
    goCart() {
      this.$router.push({
        path: '/cart',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.payment-method {
  &__row {
    &__title {
      padding: 8px 0;
      color: #47516a;
    }
    &__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 16px;
      line-height: 40px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #c9d2e1;
      border-radius: 4px;
      opacity: 1;
      &__text {
        flex: 1;
        width: 0;
        &--margin {
          margin-left: 16px;
        }
      }
    }
  }
  &__title-area {
    margin-top: 24px;
  }
  &__title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 500;
    color: var(--color-title);
    &__logo {
      margin-bottom: 24px;
      img {
        width: 84px;
        height: 28px;
        margin-right: 6px;
        object-fit: cover;
      }
    }
  }
  &__list {
    &__item {
      height: 50px;
      &__payMethodIcon {
        width: 28px;
        margin: 0 5px 0 8px;
      }
      &__logo {
        width: 52px;
        margin-left: 10px;
      }
    }
  }
}
</style>
