<template>
  <div class="contact-information">
    <shipping-type-check
      v-if="isOpenPickUp"
      @change="changeShippingType"
    ></shipping-type-check>
    <div v-if="paymentInfo.shippingType === SHIPPING_TYPE.PICK_UP">
      <div>
        <div class="contact-information__header user-title">
          <span class="contact-information__header__title">{{ $t('payment.contactInformation') }}</span>
          <div
            v-if="!isAlreadyLogin"
            class="contact-information__header__account"
          >
            <span>{{ $t('payment.contactInformation.haveAccount') }}</span>
            <op-link
              class="contact-information__header__account__login"
              @click="login"
            >{{ $t('common.login') }}</op-link>
          </div>
        </div>
        <a-form-model
          ref="userInfoForm"
          :model="paymentInfo.shopTakeAddressDTO"
          layout="vertical"
          :rules="rules"
        >
          <div>
            <a-form-model-item
              :label="$t('payment.contactInformation.email')"
              prop="email"
            >
              <a-input
                v-model.trim="paymentInfo.shopTakeAddressDTO.email"
                size="large"
              />
            </a-form-model-item>
          </div>
          <div class="person-name">
            <div class="first-name">
              <a-form-model-item
                :label="$t('common.addressForm.firstName')"
                prop="firstName"
              >
                <a-input
                  v-model="paymentInfo.shopTakeAddressDTO.firstName"
                  size="large"
                />
              </a-form-model-item>
            </div>
            <div class="last-name">
              <a-form-model-item
                prop="lastName"
                :label="$t('common.addressForm.lastName')"
              >
                <a-input
                  v-model="paymentInfo.shopTakeAddressDTO.lastName"
                  size="large"
                />
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="contact-information__pick-up-title">{{ $t('payment.shipping.pickUpAddress') }}</div>
      <div class="contact-information__pick-up-address">{{ pickUpAddress }}</div>
      <div class="payment-step__operation">
        <op-button @click="goPayment">{{ $t('payment.shipping.continuePay') }}</op-button>
      </div>
    </div>
    <div v-else>
      <div class="contact-information__header">
        <span class="contact-information__header__title">{{ $t('payment.contactInformation') }}</span>
        <div
          v-if="!isAlreadyLogin"
          class="contact-information__header__account"
        >
          <span>{{ $t('payment.contactInformation.haveAccount') }}</span>
          <op-link
            class="contact-information__header__account__login"
            @click="login"
          >{{ $t('common.login') }}</op-link>
        </div>
      </div>
      <div
        v-if="!isAlreadyLogin"
        class="contact-information__email"
      >
        <a-form-model
          ref="emailForm"
          :model="paymentInfo.contactInformation"
          layout="vertical"
          :rules="rules"
        >
          <a-form-model-item
            :label="$t('payment.contactInformation.email')"
            prop="email"
          >
            <a-input
              v-model.trim="paymentInfo.contactInformation.email"
              size="large"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div
        v-if="isAlreadyLogin"
        class="contact-information__customer-info"
      >
        <div class="contact-information__customer-info__logo">
          <img
            src="https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/account/mine-logo.png"
          />
        </div>
        <div class="contact-information__customer-info__left">
          <div class="contact-information__customer-info__left__base-info">
            <span class="contact-information__customer-info__left__base-info__name">{{ userName }}</span>
            <span
              class="contact-information__customer-info__left__base-info__email"
            >{{ userInfo.mail }}</span>
          </div>
          <op-link @click="logout">{{ $t('payment.contactInformation.logout') }}</op-link>
        </div>
      </div>
      <div
        v-if="isAlreadyLogin"
        class="contact-information__sign-up"
      >
        <a-checkbox v-model="isSign"></a-checkbox>
        <span
          class="contact-information__sign-up__text"
        >{{ $t('payment.contactInformation.signEmail') }}</span>
      </div>

      <div v-if="isShowAddressSelector">
        <a-form-model layout="vertical">
          <a-row
            type="flex"
            justify="space-between"
            align="middle"
            class="op-space--vertical-small"
          >
            <div>{{ $t('payment.contactInformation.address') }}</div>
            <!-- 暂时隐藏该入口 -->
            <!-- <op-link @click="goAddressList">{{ $t('account.tabs.addressManage') }}</op-link> -->
          </a-row>
          <a-form-model-item>
            <a-select
              ref="addressSelect"
              v-model="currentAddressId"
              size="large"
            >
              <div
                slot="dropdownRender"
                slot-scope="menu"
              >
                <v-nodes :vnodes="menu" />
                <div class="contact-information__line"></div>
                <div
                  class="contact-information__new-address"
                  @click="newAddress"
                >{{ $t('common.addressForm.useNewAddress') }}</div>
              </div>
              <a-select-option
                v-for="item in addressList"
                :key="item.id"
                :value="item.id"
                @click="selectAddress(item)"
              >{{ getAddressInfo(item) }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <address-form
        ref="addressForm"
        :address-detail="paymentInfo.contactInformation"
      ></address-form>
      <op-checkbox
        v-if="!isAlreadyLogin"
        v-model="isSaveAddress"
        :label="$t('common.addressForm.saveToNextUse')"
      />
      <div class="payment-step__operation">
        <op-button @click="submit">{{ $t('payment.contactInformation.continueShipping') }}</op-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAddressList, addAddress, subscribe, checkIfOpenPickUP } from '@/api'
import { ADDRESS_DEFAULT_TYPE, ADDRESS_TYPE, JUDGE_TYPE, SHIPPING_TYPE } from '@/constants'
import { localStorage } from '@/utils'
import { validateMail, validateRequiredAndMaxLength } from '@/utils/validator'
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'
import ShippingTypeCheck from './shipping-type-check.vue'

export default {
  components: {
    ShippingTypeCheck,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: {
    paymentInfo: {
      type: Object,
      default: () => ({ contactInformation: {} }),
    },
    paymentSource: {
      type: String,
      default: 'payByCart',
    },
  },
  data() {
    return {
      SHIPPING_TYPE,
      addressList: [],
      currentAddressId: '',
      isSign: true,
      isNewAddress: false,
      isSaveAddress: JUDGE_TYPE.YES,
      originalAddressList: [],
      pickUpInfo: {},
    }
  },
  computed: {
    pickUpAddress() {
      const keyList = ['address', 'city', 'stateValue']
      return keyList
        .map(key => (this.pickUpInfo || {})[key] || '')
        .filter(item => !!item)
        .join(',')
    },
    isOpenPickUp() {
      return this.pickUpInfo.takeStatus === SHIPPING_TYPE.PICK_UP
    },
    rules() {
      return {
        email: [validateRequiredAndMaxLength(), validateMail()],
        firstName: [validateRequiredAndMaxLength()],
        lastName: [validateRequiredAndMaxLength()],
      }
    },
    ...mapState(['isAlreadyLogin', 'userInfo', 'shopInfo']),
    userName() {
      return (this.userInfo.firstName || '') + ' ' + (this.userInfo.lastName || '')
    },
    isShowAddressSelector() {
      return this.isAlreadyLogin && (this.addressList || []).length > 0
    },
  },

  watch: {
    isAlreadyLogin: {
      handler(val) {
        val && this.getAddressList()
      },
    },
  },
  created() {
    this.getAddressList()
    this.checkIfOpenPickUP()
  },

  methods: {
    async checkIfOpenPickUP() {
      const { data } = await checkIfOpenPickUP({ countryCode: this.shopInfo.country })
      this.pickUpInfo = data || {}
      const { address, city, stateCode, stateValue, shippingRecordDTO } = this.pickUpInfo
      this.paymentInfo.shopTakeAddressDTO = {
        ...this.paymentInfo.shopTakeAddressDTO,
        address,
        city,
        stateCode,
        stateValue,
        shippingRecordDTO,
      }
    },
    changeShippingType(val) {
      this.paymentInfo.shippingType = val
      this.$emit('hiddenNavigation')
    },
    newAddress() {
      this.paymentInfo.contactInformation = {
        countryValue: '',
        stateValue: '',
      }
      this.currentAddressId = ''
      this.isNewAddress = true
    },
    addAddress(address) {
      addAddress({
        ...address,
        addressType: ADDRESS_TYPE.POST_ADDRESS,
        defaultType: ADDRESS_DEFAULT_TYPE.NOT_DEFAULT,
      })
    },
    goAddressList() {
      this.$router.push({ path: '/account', query: { components: 'address-manage' } })
    },
    getAddressInfo(addressItem) {
      const keyList = [
        'firstName',
        'lastName',
        'address',
        'city',
        'countryValue',
        'stateValue',
        'zipCode',
      ]
      return keyList
        .map(key => addressItem[key] || '')
        .filter(item => !!item)
        .join(',')
    },
    async getAddressList() {
      const defaultContactInfo = localStorage.get('defaultContactInfo', {})
      if (!this.isAlreadyLogin) {
        this.selectAddress(defaultContactInfo)
        return
      }
      const { data } = await getAddressList({ customerId: this.userInfo.customerId })
      this.addressList = data || []
      this.originalAddressList = cloneDeep(data || [])
      this.paymentSource === 'payByCart' &&
        this.selectAddress(this.addressList[0] || defaultContactInfo)
    },

    selectAddress(address) {
      this.paymentInfo.contactInformation = address
      this.currentAddressId = address.id
      this.isNewAddress = false
    },
    subscribe() {
      subscribe({ mail: this.userInfo.mail })
    },
    login() {
      this.$store.commit('openLoginDialog', {
        currentForm: 'loginForm',
      })
    },
    logout() {
      this.$store.dispatch('logout')
    },

    async submit() {
      this.$emit('sendBuryPointer', 'spmall_payment_continuetoshippingmethod_click')
      let emailValid = true
      if (!this.isAlreadyLogin) {
        try {
          await this.$refs.emailForm.validate()
        } catch (err) {
          emailValid = false
        }
      }
      const { valid } = await (this.$refs.addressForm && this.$refs.addressForm.submit())
      if (!valid) {
        return
      }
      if (this.isAlreadyLogin) {
        const originalAddressData =
          this.originalAddressList.find(item => item.id === this.currentAddressId) || {}
        const selectedAddress = this.getAddressInfo(originalAddressData)
        const currentAddress = this.getAddressInfo(this.paymentInfo.contactInformation)
        const isSaveNewAddress = this.isNewAddress || selectedAddress !== currentAddress
        isSaveNewAddress && this.addAddress({ ...this.paymentInfo.contactInformation })
        this.$set(this.paymentInfo.contactInformation, 'email', this.userInfo.mail)
        this.isSign && this.subscribe()
      }
      if (!this.isAlreadyLogin && this.isSaveAddress === JUDGE_TYPE.YES) {
        localStorage.set('defaultContactInfo', this.paymentInfo.contactInformation)
      }
      if (!this.isAlreadyLogin && this.isSaveAddress === JUDGE_TYPE.NO) {
        localStorage.set('defaultContactInfo', {})
      }
      emailValid && valid && this.$emit('updateCurrentStep', 'shipping-method')
    },
    async goPayment() {
      try {
        await this.$refs.userInfoForm.validate()
        this.$emit('updateCurrentStep', 'checkout-method')
      } catch (err) {}
    },
    goCart() {
      this.$router.push({
        path: '/cart',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.contact-information {
  .person-name {
    display: flex;
    .first-name {
      flex: 1;
      margin-right: 16px;
    }
    .last-name {
      flex: 1;
    }
  }
  .user-title {
    margin-bottom: 16px;
  }
  &__pick-up-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: var(--color-title);
  }
  &__pick-up-address {
    padding: 24px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      color: var(--color-title);
    }
    &__account {
      color: #8991a4;
      text-align: right;
      &__login {
        margin-left: 16px;
      }
    }
  }
  &__email {
    margin: 16px 0;
  }
  &__line {
    margin-bottom: 16px;
    border-bottom: 1px solid #f4f6f8;
  }
  &__new-address {
    padding: 0 12px 16px;
    cursor: pointer;
  }
  &__customer-info {
    display: flex;
    align-items: center;
    margin: 16px 0;
    &__logo {
      width: 64px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__left {
      padding-left: 16px;
      &__base-info {
        line-height: 20px;
        color: #181e2d;
        &__name {
          margin-right: 8px;
        }
      }
    }
  }
  &__sign-up {
    margin-bottom: 16px;
    &__text {
      margin-left: 16px;
      color: #8991a4;
    }
  }

  /deep/ .ant-form-vertical .ant-form-item {
    padding-bottom: 0;
    margin-bottom: 16px;
  }
}
</style>
