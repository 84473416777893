<template>
  <div class="order-summary">
    <div class="order-summary__header">{{ $t('payment.orderSummary.title') }}</div>
    <div class="order-summary__product-list">
      <div
        v-for="item in paymentInfo.productList || []"
        :key="item.skuId + item.goodsId"
        class="order-summary__product-list__item"
      >
        <div class="order-summary__product-list__item__product-image">
          <img v-lazy="item.imgUrl" />
        </div>
        <div class="order-summary__product-list__item__right">
          <div class="order-summary__product-list__item__right__product-name">{{ item.goodsName }}</div>
          <div
            class="order-summary__product-list__item__right__sku-info"
          >{{ computedStyle(item.orderItemSpecsParamDTOList) }}</div>
          <div class="order-summary__product-list__item__right__price-info">
            <div class="order-summary__product-list__item__right__price-info__sale">
              <span>{{ item.salePrice | formatMoney }}</span>
              <span
                class="order-summary__product-list__item__right__price-info__sale__product-number"
              >{{ `× ${item.skuNum}` }}</span>
            </div>
            <div
              class="order-summary__product-list__item__right__price-info__total"
            >{{ item.saleTotalPrice | formatMoney }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="paymentSource === 'payByCart'"
      class="order-summary__apply-discount-code"
    >
      <div class="order-summary__apply-discount-code__title">
        <span>{{ $t('payment.orderSummary.discountCode') }}</span>
        <op-link
          v-if="discountCodeInfo && discountCodeInfo.discountName"
          @click="removeDiscountCode"
        >{{ $t('payment.orderSummary.cancel') }}</op-link>
      </div>
      <div
        v-if="discountCodeInfo && discountCodeInfo.discountName"
      >{{ discountCodeInfo.discountName }}</div>
      <div
        v-else
        class="order-summary__apply-discount-code__text"
      >
        <a-input
          v-model.trim="discountCode"
          size="large"
          class="order-summary__apply-discount-code__text__input"
        />
        <op-button
          :disabled="!discountCode"
          @click="applyDiscountCode"
        >{{ $t('payment.orderSummary.usage') }}</op-button>
      </div>
    </div>
    <div class="order-summary__cost-detail">
      <div class="order-summary__cost-detail__item">
        <div
          class="order-summary__cost-detail__item__title"
        >{{ $t('payment.orderSummary.subTotal') }}</div>
        <div
          class="order-summary__cost-detail__cost-detail__item__money"
        >{{ paymentInfo.costInfo.subTotalAmount | formatMoney }}</div>
      </div>
      <div
        v-if="paymentInfo.costInfo.discountAmount"
        class="order-summary__cost-detail__item"
      >
        <div
          class="order-summary__cost-detail__item__title"
        >{{ $t('payment.orderSummary.discount') }}</div>
        <div
          class="order-summary__cost-detail__item__money"
        >{{ paymentInfo.costInfo.discountAmount | formatMoney }}</div>
      </div>
      <div
        v-if="paymentInfo.costInfo.taxAmount > 0"
        class="order-summary__cost-detail__item"
      >
        <div class="order-summary__cost-detail__item__title">{{ $t('payment.orderSummary.tax') }}</div>
        <div
          class="order-summary__cost-detail__cost-detail__item__money"
        >{{ paymentInfo.costInfo.taxAmount | formatMoney }}</div>
      </div>
      <div class="order-summary__cost-detail__item">
        <div
          class="order-summary__cost-detail__item__title"
        >{{ $t('payment.orderSummary.shippingRate') }}</div>
        <div
          v-if="paymentInfo.shippingInformation.name || isPickUpInStore"
          class="order-summary__cost-detail__item__money"
        >{{ getShippingAmount | formatMoney }}</div>
        <div
          v-else
          class="order-summary__cost-detail__item__money"
          style="color: #9daac2"
        >{{ $t('payment.orderSummary.calcAtNext') }}</div>
      </div>
    </div>
    <div class="order-summary__cost-total">
      <div class="order-summary__cost-total__title">{{ $t('payment.orderSummary.total') }}</div>
      <div class="order-summary__cost-total__money amount-total--global">{{ total | formatMoney }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { localStorage } from '@/utils'
import { calculateCost, getDiscountCodeInfo, getTaxRateList } from '@/api'
import { SHIPPING_TYPE } from '@/constants'
export default {
  name: 'OrderSummary',
  components: {},
  props: {
    paymentInfo: {
      type: Object,
      default: () => ({
        contactInformation: {},
        shippingInformation: {},
        productList: [],
        discountCode: '',
      }),
    },
    paymentSource: {
      type: String,
      default: 'payByCart',
    },
  },
  data() {
    return {
      discountCode: '',
      discountCodeInfo: {},
    }
  },

  computed: {
    ...mapState(['shopInfo', 'isAlreadyLogin']),
    isPickUpInStore() {
      return this.paymentInfo.shippingType === SHIPPING_TYPE.PICK_UP
    },
    getShippingAmount() {
      return this.isPickUpInStore
        ? this.paymentInfo.shopTakeAddressDTO.shippingRecordDTO?.amount
        : this.paymentInfo.shippingInformation?.amount
    },
    total() {
      if (this.isPickUpInStore) {
        return this.paymentInfo.costInfo.totalAmount
      }
      return (
        this.paymentInfo.costInfo.totalAmount + (this.paymentInfo.shippingInformation.amount || 0)
      )
    },
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      this.paymentInfo.productList = localStorage.get('accountProductList', [])
      if (this.paymentSource === 'payByLink') {
        return
      }
      const discountCode = localStorage.get('discountCode')
      if (discountCode) {
        const discountCodeIsValid = await this.checkDiscountCodeIsValid(discountCode)
        !discountCodeIsValid && localStorage.remove('discountCode')
      }
      this.getTaxRateList()
    },
    computedStyle(specList) {
      return (specList || []).map(item => item.specsValueName).join(',')
    },
    async getTaxRateList() {
      const { data } = await getTaxRateList({})
      const taxRateList = (data || []).map(item => {
        return {
          taxId: item.id,
          name: item.taxRateName,
          rate: item.taxRateNum,
          amount: item.amount,
        }
      })
      this.paymentInfo.taxRateList = taxRateList
      this.calculate()
    },
    async calculate() {
      const calculateItemParamDTOList = (this.paymentInfo.productList || []).map(item => {
        return {
          goodsGroupId: item.categoryId,
          goodsId: item.goodsId,
          skuId: item.skuId,
          skuNum: item.skuNum,
          salePrice: item.salePrice,
        }
      })
      const params = {
        orderTaxParamDTOList: this.paymentInfo.taxRateList,
        discountCode: this.paymentInfo.discountCode,
        calculateItemParamDTOList,
      }
      const { data } = await calculateCost(params)
      this.paymentInfo.costInfo = data || {}
    },
    async checkDiscountCodeIsValid(discountCode) {
      try {
        const { data } = await getDiscountCodeInfo({ discountCode })
        this.discountCodeInfo = data || {}
        this.paymentInfo.discountCode = discountCode
        return true
      } catch (err) {
        return false
      }
    },
    async applyDiscountCode() {
      const isValid = await this.checkDiscountCodeIsValid(this.discountCode)
      isValid && this.calculate()
    },
    removeDiscountCode() {
      this.discountCodeInfo = {}
      this.discountCode = ''
      this.paymentInfo.discountCode = ''
      this.calculate()
    },
  },
}
</script>
<style lang="less" scoped>
.order-summary {
  &__header {
    padding: 16px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: var(--color-title);
    border-bottom: 1px solid #f4f6f8;
  }
  &__product-list {
    padding: 16px;
    border-bottom: 1px solid #f4f6f8;
    &__item {
      display: flex;
      align-items: center;
      padding: 8px 0;
      &__product-image {
        width: 64px;
        height: 64px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__right {
        flex: 1;
        padding-left: 16px;
        &__product-name {
          color: #181e2d;
        }
        &__sku-info {
          margin-top: 4px;
          font-size: 12px;
          color: #9daac2;
        }
        &__price-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: #181e2d;
          word-break: break-all;
          &__sale {
            width: 60%;
            padding-right: 4px;
            color: var(--color-product-sale-price);
            &__product-number {
              margin-left: 8px;
            }
          }
          &__total {
            flex: 1;
            padding-left: 4px;
          }
        }
      }
    }
  }
  &__apply-discount-code {
    padding: 16px 16px 24px;
    border-bottom: 1px solid #f4f6f8;
    &__title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      color: #47516a;
    }
    &__text {
      display: flex;
      &__input {
        border-radius: 0;
      }
      /deep/ .op-button {
        border-radius: 0;
      }
    }
  }
  &__cost-detail {
    padding: 16px;
    border-bottom: 1px solid #f4f6f8;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 30px;
      &__title {
        flex: 1;
        padding-right: 4px;
        color: var(--color-sub-title);
        word-break: break-all;
      }
      &__money {
        flex: 1;
        padding-left: 4px;
        text-align: right;
      }
    }
  }
  &__cost-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    line-height: 30px;
    &__title {
      flex: 1;
      padding-right: 4px;
      color: var(--color-sub-title);
      word-break: break-all;
    }
    &__money {
      flex: 1;
      padding-left: 4px;
      text-align: right;
      vertical-align: middle;
    }
  }
}
</style>
