<template>
  <div class="shipping-method">
    <div class="shipping-method-row">
      <div class="row-title">{{ $t('payment.contactInformation') }}</div>
      <div class="row-body">
        <div
          class="row-body-text"
        >{{ paymentInfo.contactInformation && paymentInfo.contactInformation.email }}</div>
        <op-link @click="changeAddress">{{ $t('payment.update') }}</op-link>
      </div>
    </div>
    <div class="shipping-method-row">
      <div class="row-title">{{ $t('payment.shipping.deliverTo') }}</div>
      <div class="row-body">
        <div class="row-body-text text-overflow-ellipsis">{{ addressInfo }}</div>
        <op-link @click="changeAddress">{{ $t('payment.update') }}</op-link>
      </div>
    </div>
    <div class="shipping-method-title">{{ $t('payment.shipping.method') }}</div>
    <div v-if="unHaveShippingMethod">{{ $t('payment.shipping.noShipping') }}</div>
    <div
      v-else
      class="shipping-method-list"
    >
      <a-radio-group
        v-model="paymentInfo.shippingInformation.id"
        style="width: 100%"
        @change="changeShipping"
      >
        <div
          v-for="item in shippingList"
          :key="item.id"
          class="shipping-method-list__item"
        >
          <div class="shipping-method__info">
            <a-radio :value="item.id">
              <span>{{ item.name }}</span>
            </a-radio>
            <span v-if="item.amount">{{ item.amount | formatMoney }}</span>
            <span v-else>{{ $t('payment.shipping.free') }}</span>
          </div>
        </div>
      </a-radio-group>
      <div
        v-show="isShowSelectShippingTip"
        class="select-shipping-tip"
      >{{ $t('payment.shipping.pleaseSelect') }}</div>
    </div>
    <div class="payment-step__operation">
      <op-button
        :disabled="unHaveShippingMethod"
        @click="goPayment"
      >{{ $t('payment.shipping.continuePay') }}</op-button>
    </div>
  </div>
</template>

<script>
import { getShippingList } from '@/api'
export default {
  name: 'ShippingMethod',
  components: {},
  props: {
    paymentInfo: {
      type: Object,
      default: () => ({}),
    },
    componentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      shippingList: [],
      isShowSelectShippingTip: false,
    }
  },

  computed: {
    addressInfo() {
      const keyList = [
        'firstName',
        'lastName',
        'address',
        'city',
        'countryValue',
        'stateValue',
        'zipCode',
      ]
      return keyList
        .map(key => (this.paymentInfo.contactInformation || {})[key] || '')
        .filter(item => !!item)
        .join(',')
    },
    unHaveShippingMethod() {
      return this.shippingList && this.shippingList.length === 0
    },
  },
  watch: {
    'paymentInfo.contactInformation.cityCode'(val, oldVal) {
      this.getShippingList(!!oldVal)
    },
  },

  created() {
    this.getShippingList()
  },

  methods: {
    async getShippingList(reset = false) {
      if (reset) {
        this.paymentInfo.shippingInformation = {}
      }
      const cityCode = this.paymentInfo?.contactInformation?.cityCode
      const { data } = await getShippingList({ cityCode })
      this.shippingList = data || []
      if (this.shippingList.length === 1) {
        this.paymentInfo.shippingInformation.id = this.shippingList[0].id
        this.changeShipping()
      }
    },
    changeAddress() {
      this.$emit('updateCurrentStep', 'contact-information')
    },
    getCurrentShipping() {
      return this.shippingList.find(item => {
        return item.id === this.paymentInfo.shippingInformation.id
      })
    },
    changeShipping() {
      this.paymentInfo.shippingInformation = { ...this.getCurrentShipping() }
    },
    goPayment() {
      this.$emit('sendBuryPointer', 'spmall_payment_continuetopaymentmethod_click')
      if (!this.paymentInfo.shippingInformation.id) {
        this.isShowSelectShippingTip = true
        const timer = setTimeout(() => {
          this.isShowSelectShippingTip = false
          clearTimeout(timer)
        }, 2000)

        return
      }
      this.$emit('updateCurrentStep', 'checkout-method')
    },
    goCart() {
      this.$router.push({
        path: '/cart',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.shipping-method {
  &-row {
    .row-title {
      padding: 8px 0;
      color: #47516a;
    }
    .row-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 16px;
      line-height: 40px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #c9d2e1;
      border-radius: 4px;
      opacity: 1;
      &-text {
        flex: 1;
        width: 0;
      }
    }
  }
  &-title {
    margin: 24px 0;
    font-size: 22px;
    font-weight: 500;
    color: var(--color-title);
  }
  &-list {
    position: relative;
    &__item {
      display: flex;
      align-items: center;
      height: 40px;
      color: #181e2d;
      .shipping-method__info {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        padding-left: 8px;
      }
    }
    .select-shipping-tip {
      position: fixed;
      top: 20%;
      left: 50%;
      z-index: 1000;
      padding: 16px 40px;
      color: #fff;
      background: #403d3cb3;
      border-radius: 4px;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}
</style>
